/**
 * Const with params element
 * Navigation main component
 * Display navigation
 */

/* Import */
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import Mediasocial from "./mediasocial"
import Logomyca from "../images/svg/logo_myca_white.svg"
import "../styles/components/footer.scss"

/* Declaration */
const Footer = () => {
  const {t} = useTranslation()
  useStaticQuery(graphql`
    query {
      locales: allLocale(filter: {ns: {in: ["common"]}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
  `)

  return (
    <footer className="main-footer">
      <div className="wrap-maincontent">
        <div className="footer-topline">
          <div className="footer-logo">
              <Link to="/"><Logomyca className="logo-svg" /></Link>
          </div>
          <div className="box-media-social">
            <Mediasocial />
          </div>
        </div>
        <div className="footer-bottomline">
          <div className="copyright">
            <p>© {new Date().getFullYear()}, {t('copyright-1')}</p>
            <p>{t('copyright-2')}</p>
          </div>
          <ul className="nav-terms">
            <li><span><Link to="/terms" language="en">{t('item-terms')}</Link></span></li>
            <li><span><Link to="/privacy" language="en">{t('item-privacy')}</Link></span></li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

/* Export */
export default Footer
