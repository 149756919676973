/**
 * Class element
 * Mediasocial component
 * Display the list of media social
 */
/* Import section */
import { StaticQuery, graphql } from "gatsby"
import React, { Component } from "react"
import { SocialIcon } from "react-social-icons"
import "../styles/components/mediasocial.scss"

/* Declaration main class with StaticQuery */
class Mediasocial extends Component {
  render () {
    const allsocialmedia = this.props.socialmedia.acfOptionsWebsite.businessSocialMedia

    return (
      <ul className="list-media-social">
        {allsocialmedia.map(itemsocial => (
            <MediasocialList key={itemsocial.nameSocialMedia} list={itemsocial} />
          )
        )}
      </ul>
    )
  }
}

/* Export main class with StaticQuery */
const props = () => (
  <StaticQuery
    query={graphql`
    query {
      wpPage(slug: {eq: "options-website"}) {
        acfOptionsWebsite {
          businessSocialMedia {
            nameSocialMedia
            linkSocialMedia
          }
        }
      }
    }
  `}
    render={({ wpPage }) => <Mediasocial socialmedia={wpPage} {...props} />}
  />
)
export default props

/* Declaration second class - need to sibling manageHover on each li */
class MediasocialList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      circleSocialMedia: false
    }
  }

  manageHover(e, boolStateHover){
    this.setState({
      circleSocialMedia: boolStateHover
    })
  }

  render () {
    const itemsocial= this.props.list

    return (
      <li
        onMouseEnter={() => this.manageHover(this, true)}
        onMouseLeave={() => this.manageHover(this, false)}
        role="presentation"
      >
         {this.state.circleSocialMedia ? (
            <SocialIcon url={`${itemsocial.linkSocialMedia}`} network={itemsocial.nameSocialMedia} bgColor={this.props.iconbgColorCirle} fgColor={this.props.iconfgColorCirle} className="icon-circle" target="_blank" rel="noreferrer"/>
          ) : (
            <SocialIcon url={`${itemsocial.linkSocialMedia}`} network={itemsocial.nameSocialMedia} bgColor={this.props.iconbgColorNoCirle} fgColor={this.props.iconfgColorNoCirle} className="icon-no-circle" target="_blank" rel="noreferrer"/>
          )}
      </li>
    )
  }
}

MediasocialList.defaultProps = {
  iconbgColorCirle : `#0060ab`,
  iconfgColorCirle : `#fff`,
  iconbgColorNoCirle : `#00aabc`,
  iconfgColorNoCirle : `#fff`,
}
