/**
 * Class element
 * Header component
 * Display the header
 */

/* Import */

import "../styles/components/header.scss"

import { BsList, BsX } from "react-icons/bs"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

import { AnchorLink } from "gatsby-plugin-anchor-links"
import { IconContext } from "react-icons"
import Iconmyca from "../images/svg/icon_myca_white.svg"
import Logomyca from "../images/svg/logo_myca_white.svg"
import Navmaindesktop from "./navmaindesktop"
import Switchlang from "./switchlang"

/* Declaration class */
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stateBurger: false,
      width: 0,
    }
    this.handlerButtonOnClick = this.handlerButtonOnClick.bind(this)
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener("resize", this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    if (window.innerWidth !== this.state.width) {
      this.setState({
        stateBurger: this.state.stateBurger,
        width: window.innerWidth,
      })
    }
  }

  manageBurger(boolStateBurger) {
    var wrapmenutablet = [].slice.call(
      document.getElementsByClassName("wrap-menu-tablet")
    )

    if (boolStateBurger === true && this.state.width <= 1280) {
      document.body.style.overflow = "hidden"
      wrapmenutablet[0].classList.add("show")
    } else {
      document.body.style.overflow = "auto"
      wrapmenutablet[0].classList.remove("show")
    }

    this.setState({
      stateBurger: boolStateBurger,
      width: window.innerWidth,
    })
  }

  handlerButtonOnClick() {
    if (this.state.width <= 1112) {
      var wrapmenutablet = [].slice.call(
        document.getElementsByClassName("wrap-menu-tablet")
      )
      wrapmenutablet[0].classList.remove("show")
    }

    document.body.style.overflow = "auto"

    this.setState({
      stateBurger: false,
      width: this.state.width,
    })
  }

  render() {
    const isTablet = this.state.width <= 1112
    const isOpenBurger = this.state.stateBurger === true
    const classDevice = isTablet ? "tablet" : "desktop"
    const labelBurger = isOpenBurger ? "Close the menu" : "Open the menu"

    return (
      <StaticQuery
        query={graphql`
          query {
            locales: allLocale(filter: { ns: { in: ["common"] } }) {
              edges {
                node {
                  ns
                  data
                  language
                }
              }
            }
          }
        `}
        render={(data) => {
          const { t } = useTranslation()

          return (
            <header className="main-header">
              <div className={`fixed-header ${classDevice} wrap-maincontent`}>
                <div className="wrap-header">
                  <div className={`main-logo ${classDevice}`}>
                    <Link to="/">
                      {isTablet ? (
                        <Iconmyca className="logo-svg" />
                      ) : (
                        <Logomyca className="logo-svg" />
                      )}
                    </Link>
                  </div>

                  {!isTablet && <Navmaindesktop />}

                  <div className="wrap-cta-header">
                    {isTablet && (
                      <div className="menu-burger">
                        <button
                          className="button-burger"
                          onClick={() =>
                            this.manageBurger(!this.state.stateBurger)
                          }
                          aria-label={labelBurger}
                        >
                          <span>
                            <IconContext.Provider
                              value={{ className: "svg-burger" }}
                            >
                              <i aria-hidden="true">
                                {" "}
                                {isOpenBurger ? <BsX /> : <BsList />}
                              </i>
                            </IconContext.Provider>
                          </span>
                        </button>
                      </div>
                    )}

                    <Switchlang
                      className={`cta-lang ${classDevice}`}
                      tLinkSlug={this.props.tLinkSlug}
                      onClick={this.handlerButtonOnClick}
                    />
                  </div>
                </div>
              </div>

              {isTablet && (
                <div className="wrap-menu-tablet">
                  <ul className="tablet-menu">
                    <li>
                      <span>
                        <AnchorLink
                          onAnchorLinkClick={this.handlerButtonOnClick}
                          to={t("slugitem-nav-about")}
                          title={t("item-nav-about")}
                        >
                          {t("item-nav-about")}
                        </AnchorLink>
                      </span>
                    </li>
                    <li>
                      <span>
                        <Link
                          onClick={this.handlerButtonOnClick}
                          to={t("slugitem-nav-services")}
                        >
                          {t("item-nav-services")}
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span>
                        <Link
                          onClick={this.handlerButtonOnClick}
                          to={t("slugitem-nav-contact")}
                        >
                          {t("item-nav-contact")}
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span>
                        <Link
                          onClick={this.handlerButtonOnClick}
                          to={t("slugitem-nav-solutions")}
                        >
                          {t("item-nav-solutions")}
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span>
                        <Link
                          onClick={this.handlerButtonOnClick}
                          to={t("slugitem-nav-careers")}
                        >
                          {t("item-nav-careers")}
                        </Link>
                      </span>
                    </li>
                  </ul>
                  <div className="box-cta">
                    <Link
                      className="cta-button white-format cta-apply"
                      to={t("slug-apply")}
                      onClick={this.handlerButtonOnClick}
                    >
                      <span className="wrap-spanbutton">
                        <span className="spanbutton-label">
                          {t("cta-apply")}
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              )}
            </header>
          )
        }}
      />
    )
  }
}

/* Export */
export default Header
