/**
 * Const with params element
 * Switch lang component
 * Display cta to switch language
 */

/* Import */
import * as React from "react"
import PropTypes from "prop-types"
import {Link, useI18next, I18nextContext} from "gatsby-plugin-react-i18next"

/* Declaration */
const Switchlang = ({ className, tLinkSlug, onClick }) => {
  const {originalPath} = useI18next()
  const context = React.useContext(I18nextContext)
  var cLang = context.language
  var tCurrentLang = (cLang === "en") ? "fr":"en"
  var toLink = (tLinkSlug === null ) ? originalPath:tLinkSlug

  return (
    <Link onClick={onClick} className={className} to={toLink} language={tCurrentLang}>{tCurrentLang.toUpperCase()}</Link>
  )
}

/* Properties */
Switchlang.propTypes = {
  tLinkSlug: PropTypes.string,
}

Switchlang.defaultProps = {
  tLinkSlug: null,
}

/* Export */
export default Switchlang
