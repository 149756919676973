/**
 * Const with params element
 * Navigation main desktop component
 * Display navigation in dekstop
 */

/* Import */

import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import { graphql, useStaticQuery } from "gatsby"

import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"

/* Declaration */
const Navmaindesktop = () => {
  const { t } = useTranslation()
  useStaticQuery(graphql`
    query {
      locales: allLocale(filter: { ns: { in: ["common"] } }) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
  `)

  return (
    <ul className="desktop-menu">
      <li>
        <span>
          <AnchorLink to={t("slugitem-nav-about")} title={t("item-nav-about")}>
            {t("item-nav-about")}
          </AnchorLink>
        </span>
      </li>
      <li>
        <span>
          <Link activeClassName="active" to={t("slugitem-nav-services")}>
            {t("item-nav-services")}
          </Link>
        </span>
      </li>
      <li>
        <span>
          <Link activeClassName="active" to={t("slugitem-nav-solutions")}>
            {t("item-nav-solutions")}
          </Link>
        </span>
      </li>
      <li>
        <span>
          <Link activeClassName="active" to={t("slugitem-nav-careers")}>
            {t("item-nav-careers")}
          </Link>
        </span>
      </li>
      <li>
        <span>
          <Link activeClassName="active" to={t("slugitem-nav-contact")}>
            {t("item-nav-contact")}
          </Link>
        </span>
      </li>
    </ul>
  )
}

/* Export */
export default Navmaindesktop
